@import "sanitize.css";
@import "sanitize.css/forms.css";
@import "sanitize.css/typography.css";

html,
body {
	min-height: 100vh;
	font-size: 100%;
}

body {
	--text-color: #4d4d4c;
	--bg-color: #fff;
	--anchor-color: #4271ae;
	--visited-color: #8959a8;

	background: var(--bg-color);
	color: var(--text-color);
	max-width: 100vw;
	width: 80rem;
	margin: auto;
	padding: 0 1rem;

	&.dark-theme {
		--text-color: #eff1f5;
		--bg-color: #2b303b;
		--anchor-color: #8fa1b3;
		--visited-color: #b48ead;
	}
}

section {
	width: 100%;
}

a {
	color: var(--anchor-color);

	&:visited { color: var(--visited-color); }
}

.flex {
	display: flex;

	&.column { flex-direction: column; }

	&.centered {
		justify-content: center;
		align-items: center;
	}

	.spacer { flex-grow: 1; }
}

.hidden { display: none; }
